<template>
  <div>
    <h1 class="mb-0">Restablecer contraseña</h1>
    <p>
      Ingrese su correo electrónico para enviarle las instrucciones necesarias
      para Restablecer su contraseña.
    </p>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          vid="email"
          name="Correo electrónico"
          rules="required|email"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="rut">Correo electrónico</label>
            <input
              type="text"
              :class="
                'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
              "
              id="email"
              name="email"
              v-model="user.email"
              placeholder="Ingrese su correo electrónico"
              required
            />
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <div class="d-inline-block w-100">
          <button type="submit" class="btn btn-primary float-right">
            <span v-if="isLoading">
              <b-spinner small type="grow"></b-spinner>
              Cargando...
            </span>
            <span v-else> Restablecer contraseña </span>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { core } from "@/config/pluginInit";
export default {
  name: "RecoverPassword",
  data: () => ({
    user: {
      email: "",
    },
  }),
  methods: {
    ...mapActions({
      forgotPassword: "forgotPassword",
    }),
    async onSubmit() {
      const resp = await this.forgotPassword(this.user);
      if (resp?.status == 200) {
        core.showSnackbar("success", resp.data.message);
        this.$router.push("/auth/login");
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
    }),
  },
};
</script>
